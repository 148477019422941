<template>
    <div>
        <v-row no-gutters align="center" justify="end">
            <v-btn depressed color="primary" rounded @click="openCreateDialog">
                <v-icon left>mdi-plus</v-icon>
                <span>New Document</span>
            </v-btn>

            <DocumentForm
                v-model="createDialog"
                title="New Document"
                :tool="tool"
                @closeDialog="closeCreateDialog"
                formUser="docTechnical"
            />
        </v-row>
        <v-data-table
            v-if="tool"
            hide-default-footer
            disable-pagination
            :loading="loading"
            :headers="headers"
            :items="filtered"
            class="my-4"
            :mobile-breakpoint="0"
        >
            <template v-slot:[`item.index`]="{ item }">
                {{
                    tool.technicalDocuments.findIndex(doc => {
                        return doc.id == item.id
                    }) + 1
                }}</template
            >
            <template v-slot:[`header.name`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="documentDescription"
                    prepend-icon="mdi-filter"
                />
            </template>
            <template v-slot:[`item.filename`]="{ item }">
                <a @click="click(item)">{{ item.filename }}</a></template
            >
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon small @click="deleteItem(item)">
                    <v-icon small>mdi-trash-can</v-icon>
                </v-btn>

                <v-dialog
                    v-model="deleteDialog"
                    :retain-focus="false"
                    persistent
                    max-width="400px"
                >
                    <v-card>
                        <v-card-title class="text-h5"
                            >Delete Document</v-card-title
                        >
                        <v-card-text>
                            Are you sure you want to delete this document?
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                text
                                color="secondary"
                                @click="deleteDialog = false"
                            >
                                Close
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="deleteDoc" text color="error">
                                Delete
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import DocumentForm from '@/components/Settings/DocumentForm'
import { storage } from '@/services/firebase'
import { mapMutations } from 'vuex'
import _ from 'lodash'
import API from '@/services/api'
export default {
    name: 'TechnicalDocuments',
    components: {
        DocumentForm,
    },
    props: {
        tool: Object,
    },
    data() {
        return {
            folderDocuments: 'technical_documents',
            companyId: JSON.parse(localStorage.getItem('company')),
            createDialog: false,
            loading: false,
            deleteDialog: false,
            loadingError: false,
            errorMsg: null,
            indexItem: null,
            docToDelete: null,
            documentDescription: null,
            headers: [
                { text: 'INDEX', value: 'index', class: 'grey lighten-3' },
                {
                    text: 'DESCRIPTION',
                    value: 'name',
                    class: 'grey lighten-3',
                    sortable: false,
                },
                {
                    text: 'DOCUMENT',
                    value: 'filename',
                    class: 'grey lighten-3',
                },
                {
                    text: 'ACTIONS',
                    value: 'actions',
                    class: 'grey lighten-3',
                    sortable: false,
                },
            ],
        }
    },
    computed: {
        filtered: function() {
            return this.tool.technicalDocuments.filter(q => {
                if (this.documentDescription) {
                    return q.name
                        .toLowerCase()
                        .includes(this.documentDescription.toLowerCase())
                } else {
                    return true
                }
            })
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        makeid() {
            var length = 6
            var result = ''
            var characters =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            var charactersLength = characters.length
            for (var i = 0; i < length; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                )
            }
            return result.toUpperCase()
        },
        openCreateDialog: function() {
            this.createDialog = true
        },
        closeCreateDialog: function() {
            this.createDialog = false
        },
        click: function(item) {
            if (this.$device.ios) {
                window.location.assign(item.docURL)
            } else {
                window.open(item.docURL, '_blank')
            }
        },
        deleteItem(item) {
            this.deleteDialog = true
            this.docToDelete = item
            this.indexItem = this.tool.technicalDocuments.findIndex(
                x => x.id == this.docToDelete.id
            )
        },
        deleteDoc() {
            this.tool.technicalDocuments.splice(this.indexItem, 1)
            this.upload()
        },
        deleteOnStorage: async function(file) {
            try {
                const path = `${this.companyId}/${this.folderDocuments}`
                const storageRef = storage().ref(path)
                const refDoc = storageRef.child(file)
                await refDoc.delete()
            } catch (error) {
                console.log(error.message)
            }
        },
        upload: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const technicalDocuments = _.cloneDeep(
                    this.tool.technicalDocuments
                )
                technicalDocuments.forEach(i => {
                    delete i.docURL
                })
                await this.deleteOnStorage(this.docToDelete.file)
                await API.updateTools(this.tool.id, { technicalDocuments })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.deleteDialog = false
            }
        },
    },
}
</script>
